import { graphql } from "@/gql";

export const GET_PORT_GEOGRAPHIC_QUERY = graphql(`
  query GetPortGeographic {
    port {
      center {
        latitude
        longitude
      }
      zoomLevel
      id
      mapTmsUrl
    }
  }
`);

export const GET_WHARVES_QUERY = graphql(`
  query getWharvesMultiSelect {
    port {
      id
      wharves {
        id
        name
      }
    }
  }
`);

export const GET_PORT_QUERY = graphql(`
  query GetPort {
    port {
      name
      id
    }
  }
`);

export const DELETE_CAP_BEAM_INSPECTION_MUTATION = graphql(`
  mutation DeleteCapBeamInspection($id: ID!) {
    deleteCapBeamInspection(id: $id)
  }
`);

export const GET_CAP_BEAM_INSPECTIONS_QUERY = graphql(`
  query GetCapBeamInspections($id: ID!) {
    capBeam(id: $id) {
      id
      inspections {
        inspectionDate
        id
        steelCondition
        decays {
          depth
          width
          length
          span
          id
          location
        }
        inspectedBy {
          firstName
          lastName
        }
        structurallyInadequate
      }
    }
  }
`);

export const CREATE_CAP_BEAM_INSPECTION_MUTATION = graphql(`
  mutation CreateCapBeamInspection($input: CapBeamInspectionInput!) {
    createCapBeamInspection(input: $input) {
      id
      inspectionDate
      steelCondition
      decays {
        width
        depth
        length
        span
        location
      }
      inspectedBy {
        firstName
        lastName
      }
      structurallyInadequate
    }
  }
`);

export const CREATE_PILE_INSPECTION_MUTATION = graphql(`
  mutation CreatePileInspection($input: PileInspectionInput!) {
    createPileInspection(input: $input) {
      id
      inspectionDate
      wastedDiameter
      seabedDiameter
      condition
      jacketCondition
      inspectedBy {
        firstName
        lastName
      }
    }
  }
`);

export const GET_PILE_INSPECTIONS_QUERY = graphql(`
  query GetPileInspections($id: ID!) {
    pile(id: $id) {
      id
      inspections {
        inspectionDate
        id
        condition
        jacketCondition
        seabedDiameter
        wastedDiameter
        inspectedBy {
          firstName
          lastName
        }
      }
    }
  }
`);

export const GET_CAP_BEAM_PROPERTIES_QUERY = graphql(`
  query GetCapBeamProperties($id: ID!) {
    capBeam(id: $id) {
      id
      properties {
        id
        appointedOn
        breadth
        depth
      }
    }
  }
`);

export const GET_PILE_PROPERTIES_QUERY = graphql(`
  query GetPileProperties($id: ID!) {
    pile(id: $id) {
      id
      properties {
        id
        length
        headDiameter
        appointedOn
        embedmentDepth
      }
    }
  }
`);

export const GET_PILE_TIMELINE_QUERY = graphql(`
  query GetPileTimeline($id: ID!) {
    pile(id: $id) {
      id
      inspections {
        id
        inspectionDate
      }
      properties {
        id
        appointedOn
      }
      timeline {
        capacityUtilization
        condition
        eventId
        eventType
      }
    }
  }
`);

export const GET_CAP_BEAM_TIMELINE_QUERY = graphql(`
  query GetCapBeamTimeline($id: ID!) {
    capBeam(id: $id) {
      id
      inspections {
        id
        inspectionDate
      }
      properties {
        id
        appointedOn
      }
      timeline {
        capacityUtilization
        condition
        eventId
        eventType
      }
    }
  }
`);

export const GET_CAP_BEAMS_TABLE_QUERY = graphql(`
  query GetCapBeamsTable {
    capBeams {
      edges {
        node {
          id
          condition
          name
          wharf {
            name
          }
          bent
          rowSpan
          capacityUtilization
          inspectionRequired
          inspections {
            inspectionDate
            inspectedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`);

export const GET_PILES_TABLE_QUERY = graphql(`
  query GetPilesTable {
    piles {
      edges {
        node {
          id
          condition
          name
          wharf {
            name
          }
          bent
          row
          broken
          side
          capacityUtilization
          inspectionRequired
          inspections {
            wastedDiameter
            seabedDiameter
            inspectionDate
            inspectedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`);

export const GET_INSPECTION_FREQUENCIES_QUERY = graphql(`
  query GetInspectionFrequencies {
    inspectionFrequencies {
      id
      period
      wharf {
        name
      }
      assetType
    }
  }
`);

export const GET_CAP_BEAMS_GEOGRAPHIC_QUERY = graphql(`
  query GetCapBeamsGeographic {
    capBeams {
      edges {
        node {
          coordinates {
            latitude
            longitude
          }
          id
          condition
          inCommission
          name
          openspaceLink
          inspectionRequired
        }
      }
    }
  }
`);

export const GET_PILES_GEOGRAPHIC_QUERY = graphql(`
  query GetPilesGeographic {
    piles {
      edges {
        node {
          id
          coordinates {
            latitude
            longitude
          }
          condition
          inCommission
          name
          openspaceLink
          inspectionRequired
        }
      }
    }
  }
`);

export const GET_NOTES_QUERY = graphql(`
  query GetNotes($assetId: ID!, $assetType: AssetType!) {
    notes(assetId: $assetId, assetType: $assetType) {
      id
      postedBy {
        firstName
        lastName
      }
      postedDate
      images
      context
    }
  }
`);

export const DELETE_ASSET_MUTATION = graphql(`
  mutation DeleteAsset($assetType: AssetType!, $assetId: ID!) {
    deleteAsset(assetType: $assetType, assetId: $assetId)
  }
`);

export const GET_PILES_GEO_EDIT_QUERY = graphql(`
  query GetPilesGeoEdit {
    piles {
      edges {
        node {
          id
          coordinates {
            latitude
            longitude
          }
          inCommission
          name
        }
      }
    }
  }
`);

export const GET_CAP_BEAMS_GEO_EDIT_QUERY = graphql(`
  query GetCapBeamsGeoEdit {
    capBeams {
      edges {
        node {
          coordinates {
            latitude
            longitude
          }
          id
          inCommission
          name
        }
      }
    }
  }
`);

export const GET_CAP_BEAM_DEFAULT_PROPERTIES_QUERY = graphql(`
  query GetCapBeamDefaultProperties {
    capBeamDefaultProperties {
      id
      wharf
      breadth
      depth
    }
  }
`);

export const GET_PILE_DEFAULT_PROPERTIES_QUERY = graphql(`
  query GetPileDefaultProperties {
    pileDefaultProperties {
      id
      length
      row
      wharf
      headDiameter
      embedmentDepth
    }
  }
`);

export const UPDATE_COMMISSION_STATUS_MUTATION = graphql(`
  mutation UpdateCommissionStatus(
    $assetType: AssetType!
    $assetId: ID!
    $inCommission: Boolean!
  ) {
    updateCommissionStatus(
      assetType: $assetType
      assetId: $assetId
      inCommission: $inCommission
    )
  }
`);
